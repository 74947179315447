.button {
  display: flex;
  position: relative;
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: none;
  white-space: nowrap;
  font-size: 100%;
  line-height: 1;
  font-family: $primary-font;
  font-weight: 400;
  text-decoration: none;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &[disabled] {
    background-color: #333333 !important;
    color: #999999 !important;
    cursor: default;
  }

  &.sm {
    height: 38px;
    padding: 0 1rem;
  }
  &.md {
    height: 48px;
    padding: 0 1rem;
    max-width: 200px;
    width: 100%;
  }

  &.dark {
    background-color: $color-black;
    color: $color-white;
  }
  &.orange {
    background-color: $color-orange;
    color: $color-white;
  }

  &.gradient {
    background: $background-gradiend;
    color: $color-white;
  }

  &.rounded {
    &.sm {
      border-radius: 19px;
    }
    &.md {
      border-radius: 24px;
    }
  }
}
