.footer {
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;

  @media (max-width: 1023px) {
    display: none;
  }
}

.footer-content {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  height: 108px;
  display: flex;
  align-items: center;
  background-color: $color-black;
  color: $color-white;

  @media (max-width: 1023px) {
    height: 75px;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .copy {
    @media (max-width: 1023px) {
      font-size: 12px;
      margin-top: 0.5rem;
    }
  }

  .social {
    display: flex;

    @media (min-width: 1024px) {
      margin-left: auto;
    }

    a {
      display: block;
      margin-left: 0.5rem;
    }
  }
}
