.accordion {
  padding: 0.75rem 0;
  border-top: 1px solid #e0e0e0;

  &:first-of-type {
    border-top: none;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 18px;
      color: $color-black;
      margin: 0px;

      @media (max-width: 1023px) {
        font-size: 16px;
      }
    }

    .accordion-icon {
      margin-left: auto;

      &.expanded {
        transform: rotate(180deg);
      }
    }
  }

  .accordion-content {
    height: 0;
    position: relative;
    overflow: hidden;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 14px;
    color: #333333;

    &.expanded {
      height: auto;

      .content {
        visibility: visible;
      }
    }

    .content {
      visibility: hidden;
      padding: 1rem 0;
      line-height: 1.2;
    }
  }
}
