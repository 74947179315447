.Modal {
  position: fixed;
  z-index: 992;
  background-color: $color-white;
  width: calc(100% - 2rem);
  max-width: 768px;

  height: auto;
  max-height: calc(100% - 2rem);
  border-radius: 0.25rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.95);
  box-sizing: border-box;
  box-shadow: 0px 7px 35px -14px rgba(0, 0, 0, 0.5);
  flex-flow: column;
  visibility: hidden;
  opacity: 0;

  transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);

  &.xs {
    max-width: 400px;
  }
  &.sm {
    max-width: 768px;
  }
  &.md {
    max-width: 1024px;
  }
  &.lg {
    max-width: 1280px;
  }
  &.xl {
    max-width: 1920px;
  }

  &.open {
    display: flex;
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  .Modal-header {
    display: flex;
    align-items: center;
    padding: 2rem 2rem 0 2rem;

    @media (max-width: 767px) {
      padding: 1rem 1rem 0 1rem;
    }
  }

  .Modal-content {
    padding: 2rem;
    @media (max-width: 767px) {
      padding: 1rem;
    }
  }
}

.Backdrop {
  position: fixed;
  z-index: 991;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);

  opacity: 0;

  &.open {
    opacity: 1;
  }
}
