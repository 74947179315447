.form {
  .form-item {
    margin-bottom: 40px;
    padding: 5px 0;
    position: relative;

    @media (max-height: 768px) {
      margin-bottom: 20px;
    }

    @media (max-width: 1023px) {
      margin-bottom: 20px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    input,
    textarea {
      width: 100%;
      box-sizing: border-box;
      border: 0;
      background-color: transparent;
      outline: none;
      font-family: inherit;
      color: $color-black;
      font-size: 36px;
      font-weight: 700;
      border-radius: 0;

      @media (max-width: 1023px) {
        font-size: 21px;
      }
    }

    input {
      text-align: right;
    }
    textarea {
      margin-top: 5px;
      border-top: 1px solid $color-black;
      resize: none;
      background-color: $color-white;
      min-height: 100px;
    }

    label {
      color: #7f7f7f;
      white-space: nowrap;
      font-size: 20px;
      @media (max-width: 1023px) {
        font-size: 16px;
      }
    }

    &.input {
      border-bottom: 1px solid $color-black;
      display: flex;
      align-items: flex-end;
    }
    &.textarea {
      display: flex;
      flex-direction: column;

      label {
        margin-top: 15px;
        @media (max-width: 1023px) {
          margin-top: 10px;
        }
      }
    }

    .form-error {
      position: absolute;
      right: 0;
      bottom: -20px;
      color: #f20b0b;
      font-size: 13px;
    }
  }

  .hint {
    font-size: 13px;
    margin-bottom: 15px;
  }
}

.flag-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-top: 30px;

  @media (max-width: 599px) {
    grid-gap: 15px;
    margin-top: 15px;
  }

  .flag-item {
    width: 100%;
    display: flex;
    align-items: center;
    color: #1c1916;

    .checkbox {
      margin-right: 14px;
    }
  }
}

.checkbox {
  button {
    width: 25px;
    height: 25px;
    border: 1px solid $color-black;
    background-color: transparent;
    border-radius: 0.25rem;
    cursor: pointer;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.checked {
      background-color: $color-black;
      svg {
        width: 100%;
        height: 100%;
        fill: $color-white;
      }
    }
  }
}
