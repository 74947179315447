.step-form {
  max-width: 500px;
  width: 100%;
}

.step-title {
  -webkit-text-stroke: 1px $color-orange;
  color: transparent;
  text-transform: uppercase;

  @media (max-width: 1309px) {
    font-size: 60px !important;
    line-height: 60px !important;
  }

  @media (max-width: 1023px) {
    font-size: 45px !important;
    margin-bottom: 40px !important;
    line-height: 45px !important;
  }
}

.step-subtitle {
  color: #7f7f7f;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  margin: 0;
}

.action-title {
  font-size: 48px;
  line-height: 90px;
  margin: 0;
  font-weight: 900;

  @media (max-width: 1023px) {
    font-size: 48px !important;
    line-height: 50px !important;
  }

  &.loading {
    -webkit-text-stroke: 1px $color-black;
    color: transparent;
  }

  &.success {
    color: $color-orange;
    margin-bottom: 30px;
  }

  &.small {
    font-size: 64px;
  }
}

.step-content {
  margin: 30px 0;

  .form {
    min-height: 394px;

    @media (max-width: 767px) {
      min-height: 310px;
    }
  }
}

.dropzone {
  background-color: $color-white;
  border-radius: 45px;
  height: 250px;
  width: 100%;
  border: 1px dashed #cac8c8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 1023px) {
    height: 60px;
  }

  .label {
    text-align: center;
    color: #7f7f7f;
    font-style: italic;
    font-weight: 300;
    font-size: 20px;

    span {
      text-decoration: underline;
    }
  }
}

.ipfs-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: $color-black;
  border-bottom: 1px solid $color-black;
  box-sizing: border-box;
  border-radius: 0;
}

.step-actions {
  display: flex;

  &.faucet-done {
    justify-content: center;
  }

  @media (min-width: 600px) {
    justify-content: space-between;
  }

  @media (max-width: 599px) {
    flex-flow: column;
    align-items: center;
    .button {
      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  // &.mint {
  //   justify-content: center;
  //   @media (max-width: 1023px) {
  //     align-items: center;
  //   }
  // }

  .advanced-options {
    position: relative;
    background-color: $color-white;
    height: 48px;
    border-radius: 24px;
    padding: 0 1rem 0 64px;
    transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);

    @media (min-width: 1024px) {
      margin-right: auto;
    }

    @media (max-width: 599px) {
      height: 35px;
      max-width: 200px;
      margin: 0 auto 1rem auto;
      padding: 0 1rem 0 51px;
    }

    .circle {
      position: absolute;
      left: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: $color-orange;
      transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
      @media (max-width: 599px) {
        width: 35px;
        height: 35px;
      }
    }

    .text {
      font-size: 16px;
      color: #cac8c8;
      transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &.on {
      padding: 0 64px 0 1rem;
      @media (max-width: 599px) {
        padding: 0 51px 0 1rem;
      }

      .circle {
        left: calc(100% - 48px);
        @media (max-width: 599px) {
          left: calc(100% - 35px);
        }
      }

      .text {
        color: $color-white;
      }
    }
  }
}

.file-preview {
  background-color: $color-white;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;

  .file-name {
    word-break: break-word;
    margin: 0 auto 0 1rem;
    font-size: 0.9rem;
  }

  .button {
    margin-left: 1rem;
  }
}

.advanced-form {
  background-color: $color-white;
  padding: 1rem;

  .close-advanced {
    background-color: $color-black;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    svg,
    path {
      fill: $color-white;
    }
  }
}

.mint-price {
  display: flex;
  flex-flow: column;
  margin-bottom: 50px;
  padding: 0 30px;

  span {
    font-size: 18px;
    color: #1c1916;
    text-align: center;

    @media (max-width: 1023px) {
      font-size: 16px;
    }
  }

  .price {
    font-size: 64px;
    line-height: 50px;
    color: $color-orange;
    font-weight: 700;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $color-black;
    margin: 20px 0 40px;
  }
}

.acceptance {
  display: flex;
  margin-top: 50px;
  padding: 0 10px;

  @media (max-width: 1023px) {
    padding: 0;
    margin-top: 30px;
  }

  p {
    margin: 0 0 0 15px;
    font-size: 14px;
    line-height: 20px;
    font-style: italic;
    color: #1c1916;

    @media (max-width: 1023px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.wheels {
  position: relative;
  top: 0;
  width: 500px;
  height: 500px;

  @media (max-height: 768px) {
    width: 400px;
    height: 400px;
  }

  @media (max-width: 1023px) {
    margin-top: 50px;
    width: 300px;
    height: 300px;
  }

  .wheel {
    position: absolute;

    img {
      width: 100%;
    }

    &.wheel-1 {
      width: 43%;
      top: 0%;
      left: 18%;
      animation: wheel1 5s infinite linear;
    }
    &.wheel-2 {
      width: 30%;
      top: 33%;
      left: 58%;
      animation: wheel2 5s infinite linear;
    }
    &.wheel-3 {
      width: 45%;
      bottom: 0%;
      left: 22%;
      animation: wheel1 5s infinite linear;
    }
  }
}

@keyframes wheel1 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wheel2 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}

.qr-modal {
  span {
    display: block;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: $color-black;
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 1rem;
  }

  img {
    margin: auto;
    width: 100%;
    max-width: 320px;
  }
}

.tab {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;

  .tab-filter {
    width: 100%;
    max-width: 200px;
    text-align: center;
    border-radius: 20px;
    padding: 0.5rem 0;
    cursor: pointer;
    color: #666666;

    @media (max-width: 767px) {
      font-size: 14px;
    }

    &.active {
      border: 1px solid $color-black;
      color: $color-black;
      font-weight: 700;
    }
  }
}
