.Icon {
  svg {
    width: 100%;
    height: auto;
  }
  &.xs {
    width: 18px;
    height: 18px;
    flex: 0 0 18px;
  }
  &.sm {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
  }
  &.md {
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
  }

  &.lg {
    width: 48px;
    height: 48px;
    flex: 0 0 48px;
  }
}
