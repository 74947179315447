@import 'variables.scss';
@import 'normalize.scss';
@import 'buttons.scss';
@import 'header.scss';
@import 'footer.scss';
@import 'create.scss';
@import 'form.scss';
@import 'modal.scss';
@import 'accordion.scss';

body {
  font-family: $primary-font;
  font-size: 100%;
  overflow: hidden;
  color: $color-black;
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.flex {
  display: flex;
}

.ml-1 {
  margin-left: 1rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

.w-full {
  width: 100%;
}

.img-fit-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-content {
  max-height: calc(100vh - 240px);
}

.step-3-image {
  max-height: calc(100vh - 330px);
}

.sm-hidden {
  @media (max-width: 1023px) {
    display: none !important;
  }
}

img {
  display: block;

  &.fit {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .app-content {
    position: relative;
    height: calc(100vh - 240px);

    @media (max-width: 1023px) {
      // height: calc(100vh - 155px);
      overflow-y: auto;
    }

    .app-backdrop {
      position: absolute;
      height: calc(100% - 40px);
      background-color: #f8f8f8;
      width: 100%;
      top: 20px;
      z-index: -2;
    }
  }
}

.page-template {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0 80px;
  box-sizing: border-box;

  @media (max-width: 1023px) {
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;
    height: auto;
    min-height: 100%;
  }

  .left-col {
    width: 100%;
  }
  .right-col {
    width: 100%;
    position: relative;
  }
}

.hero {
  display: flex;
  flex-direction: column;
  @media (max-width: 1023px) {
    text-align: center;
  }
  h1 {
    font-size: 82px;
    line-height: 90px;
    margin: 0;
    font-weight: 900;

    @media (max-width: 1023px) {
      font-size: 64px;
      line-height: 70px;
    }

    @media (max-height: 768px) {
      line-height: 70px;
    }
  }

  p {
    opacity: 0.5;
    font-size: 20px;
    font-weight: 300;
    margin: 2rem 0;
    @media (max-height: 768px) {
      margin: 1rem 0;
    }
  }

  .button {
    margin-right: auto;
    @media (max-width: 1023px) {
      margin: 0 auto;
    }
  }

  .hero-small-text {
    margin-top: 1rem;
    font-size: 14px;
  }
}

.lines {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: -1;
  background-image: url(../images/lines.svg);
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1023px) {
    background-size: cover;
  }
}

.image-wrapper {
  &.homepage {
    position: relative;
    height: 100%;
    padding: 10px 0;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .circle-1 {
      position: absolute;
      z-index: -1;
    }
    .circle-2 {
      position: absolute;
      z-index: -1;
    }
  }

  &.scan {
    position: absolute;
    right: 0;
    bottom: 0;
    height: auto;

    img {
      width: 100%;
    }

    .qr {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 33%;

      span {
        font-size: min(2.5vw, 36px);
        display: block;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        color: $color-white;
        margin-bottom: 10px;
      }
    }
  }

  &.mint {
    @media (min-width: 1024px) {
      height: 100%;
    }

    img {
      @media (max-width: 1023px) {
        max-height: 50vh;
      }
    }
  }
}

.right-col {
  &.homepage {
    height: 100%;
    @media (max-width: 1023px) {
      display: none;
    }
  }
  &.scan {
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }
  &.form {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 1024px) {
      height: 100%;
    }
  }
  &.mint {
    @media (min-width: 1024px) {
      height: 100%;
    }
  }
  &.mint-wheels {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 1023px) {
      justify-content: center;
    }
  }
}

.video-responsive {
  width: 100%;
  height: 100%;
  max-width: 1024px;
  margin: auto;
  position: relative;

  @media (max-width: 1023px) {
    width: 90%;
    height: 90%;
  }

  iframe {
    height: calc(100% - 2rem);
    width: 100%;
    object-fit: contain;

    @media (max-width: 1023px) {
      height: 100%;
    }
  }
}

.faqs-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  max-width: 768px;
  margin: auto;
  padding: 20px 0;

  @media (min-width: 1024px) {
    max-height: calc(100vh - 240px);
  }
  @media (max-width: 1023px) {
    max-height: calc(100vh - 80px);
  }

  > div {
    padding: 0 1rem;
  }

  a {
    color: $color-black;
  }
}

.support {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;

  a {
    color: $color-black;
  }
}

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
}
