.header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;
  height: 130px;

  @media (max-width: 1023px) {
    padding: 0 30px;
    height: 80px;
    padding: 0 16px;
  }

  .logo {
    width: 100%;
    max-width: 200px;

    @media (max-width: 767px) {
      max-width: 150px;
    }

    img {
      width: 100%;
    }
  }

  a {
    color: $color-black;
    text-decoration: none;
  }

  .navigation-desktop {
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: 1023px) {
      display: none;
    }
  }

  .nav-primary {
    display: flex;
    margin: 0 auto;

    .nav-item {
      margin: 0 15px;

      .active {
        font-weight: 700;
        text-decoration: line-through;
      }
    }
  }

  .nav-secondary {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .navigation-mobile {
    @media (min-width: 1023px) {
      display: none;
    }

    background-color: $color-black;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;

    .navigation-close {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 20px 24px;
      box-sizing: border-box;

      .close {
        width: 32px;
        height: 32px;
        border: 2px solid $color-white;
        border-radius: 50%;
      }
    }

    .nav {
      margin: 50px 0;
      display: flex;
      flex-direction: column;
      color: $color-white;

      .nav-item {
        padding: 20px 0;
        font-size: 24px;
        font-weight: 600;
        text-align: center;

        a {
          color: $color-white;
        }

        .active {
          font-weight: 700;
          text-decoration: line-through;
        }
      }

      .button {
        font-size: 24px;
        margin-top: 20px;
        max-width: 100%;
      }
    }

    .social-nav {
      margin-top: auto;
    }

    .close-button {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid $color-white;
      cursor: pointer;
      margin-top: 75px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .footer-content {
      margin-top: auto;
    }
  }
}

.burger-menu {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 992;
  margin-left: auto;

  @media (min-width: 1024px) {
    display: none;
  }

  &.open {
    @media (min-width: 1024px) {
      .dish .burger-item.bread-bottom {
        width: 100%;
      }
    }
    @media (max-width: 1023px) {
      .dish .burger-item.bread-top {
        transform: rotate(45deg);
        top: 50%;
      }
      .dish .burger-item.meat {
        width: 0;
      }
      .dish .burger-item.bread-bottom {
        width: 100%;
        transform: rotate(-45deg);
        top: 50%;
      }
    }
  }

  .dish {
    width: 32px;
    height: 20px;
    position: relative;

    .burger-item {
      width: 100%;
      height: 3px;
      border-radius: 2px;
      background-color: $color-orange;
      position: absolute;

      &.bread-top {
        top: 0;
      }
      &.meat {
        top: 50%;
        transform: translate(0, -50%);
      }
      &.bread-bottom {
        bottom: 0;
      }
    }
  }
}
